//import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Fragment } from 'react'
import { NextSeo } from 'next-seo'
import Script from 'next/script';
import { Box } from '@mui/material';
//import { useTrack } from '../hooks'
import TopAppBar from '../components/shared/TopAppBar'
//const TopAppBar = dynamic(() => import('../components/shared/TopAppBar'))

const host = process.env.HOST_URL
//const urlMedia = process.env.REACT_APP_API_URL_MEDIA


const Layout = ({
    children,
    title = 'compara-precios.com.ar | Encuentra las Mejores Ofertas y Ahorra en tus Compras',
    description = 'En compara-precios.com.ar, te ayudamos a descubrir las mejores ofertas y comparar precios de tus productos favoritos en múltiples tiendas online. ¡Aprovecha nuestras herramientas gratuitas para ahorrar en cada compra y encontrar las mejores opciones del mercado!',
    url = 'https://compara-precios.com.ar',
    image = [
        {
            url: 'https://compara-precios.com.ar/512x512.png',
            alt: 'compara-precios.com.ar'
        },
        {
            url: 'https://compara-precios.com.ar/512x512.png',
            alt: 'compara-precios.com.ar'
        },
        {
            url: 'https://compara-precios.com.ar/512x512.png',
            alt: 'compara-precios.com.ar'
        }
    ],
    favicon = host + '/favicon.ico',
    autor = 'compara-precios.com.ar',
    showBannerPWA = true,
    ...props
}) => {

    if (props.pagename === 'politicas-de-privacidad') {
        title = 'Política de Privacidad | Compara-Precios.com.ar'
        description = 'Conoce cómo recopilamos, utilizamos y protegemos tu información personal en Compara-Precios.com.ar. Nuestra Política de Privacidad detalla los derechos de los usuarios y las medidas que tomamos para garantizar la seguridad de tus datos.'
    }

    if (props.pagename === 'politicas-de-cookies') {
        title = 'Política de Cookies | Compara-Precios.com.ar'
        description = 'Descubre cómo utilizamos las cookies en Compara-Precios.com.ar para mejorar tu experiencia de navegación. Nuestra Política de Cookies explica qué son, cómo las usamos y cómo puedes gestionar tus preferencias sobre ellas.'
    }

    if (props.pagename === 'primeros-pasos') {
        title = 'Primeros pasos'
        description = "Primeros pasos para utilizar compara-precios.com.ar"
    }


    return (
        <Fragment>

            <NextSeo

                title={title}
                description={description}
                canonical={url}
                additionalLinkTags={[
                    {
                        rel: 'icon',
                        href: '/favicon.ico',
                        type: "image/png"
                    },
                    {
                        property: 'autor',
                        content: { autor }
                    },
                    {
                        rel: 'apple-touch-icon',
                        href: '/192.png',
                        sizes: '192x192'
                    },
                    {
                        property: 'apple-mobile-web-app-title',
                        content: 'compara-precios.com.ar'
                    },
                    {
                        property: 'apple-mobile-web-app-status-bar-style',
                        content: 'default'
                    },
                    {
                        property: 'apple-mobile-web-app-capable',
                        content: 'yes'
                    },
                    {
                        property: 'theme-color',
                        content: '#212121'
                    },
                    {
                        property: 'mobile-web-app-capable',
                        content: 'yes'
                    },
                    {
                        rel: 'manifest',
                        href: '/manifest.json'
                    }
                ]}
                openGraph={{
                    url: url,
                    title: title,
                    description: description,
                    type: 'website',
                    images: image,
                    site_name: 'compara-precios.com.ar'

                }}
                facebook={{
                    appId: '712345777586000'
                }}
            />

            <Head>
                <meta
                    name='viewport'
                    content='width=device-width'
                />
                <meta name="google-adsense-account" content="ca-pub-7542824617421637" />
                <Script
                    async
                    custom-element="amp-auto-ads"
                    src="https://cdn.ampproject.org/v0/amp-auto-ads-0.1.js"
                />
                {/*
                <script async custom-element="amp-auto-ads"
                    src="https://cdn.ampproject.org/v0/amp-auto-ads-0.1.js">
                </script>
                */}
                {/*
                <Script
                    async
                    src="https://alwingulla.com/88/tag.min.js"
                    data-zone="97483"  
                    data-cfasync="false"
                />
                    
                <script src="https://alwingulla.com/88/tag.min.js" data-zone="97483" async data-cfasync="false"></script>
                 
                 <script async="async" data-cfasync="false" src="//thubanoa.com/1?z=8035159"></script>
                 */}

            </Head>

            <Box
                className='bg-green-200- flex flex-col h-full'
                style={{
                    //height: '100vh',
                }}
            >
                <Box className="" style={{ height: 64 }}>
                    <TopAppBar {...props} />
                </Box>


                {children}


            </Box>


        </Fragment>
    )
}

export default Layout
