import React, { useEffect, useRef, useState } from 'react';
import { Paper, Box, Button, Typography, Divider, Tooltip, Stack, IconButton, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';
import { useRouter } from 'next/router';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/css/virtual';
import { Navigation, FreeMode, Scrollbar, Mousewheel, Virtual } from "swiper";
import copy from 'copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import IconCp from '../shared/IconCp'
import RemoveIcon from '@mui/icons-material/Remove';
import Valor from '../shared/Valor'
import TerminosBuscados from './TerminosBuscados';
import useMediaQuery from '@mui/material/useMediaQuery';

import AdSense from './AdSense';
//import Adsterra from './Adsterra';


const apiUrl = process.env.REACT_APP_API_URL;

const Botones = (props) => {

    const router = useRouter();
    const dispatch = useDispatch();
    const state = useSelector(({ origenes }) => origenes.origenes);
    /*
        function replaceMultipleOccurrences(str, searchValues) {
            searchValues.forEach(value => {
                const regex = new RegExp(value, 'gi');
                str = str.replace(regex, '');
            });
            return str;
        }
    
        function removeUnits(str) {
            // Expresión regular para encontrar números seguidos de unidades (g, kg, lb, oz, mg, v) o letras seguidas de números con unidades
            const regex = /\b([a-zA-Z]*\d+)\s?(g|kg|lb|oz|mg|v)\b/gi;
    
            // Reemplaza los valores encontrados con solo el número o la parte alfanumérica
            return str.replace(regex, (match, p1) => {
                // Remueve la unidad pero conserva el número o el texto alfanumérico
                return p1.replace(/[a-zA-Z]/g, '').trim();
            });
        }
    */
    const enviar = (terminoBusca) => {

        if (terminoBusca.trim()) {
            router.push(`/${state.categoria}/${terminoBusca}`);
            /*
            dispatch(Actions.updateCompara({ id: 'termino', value: terminoBusca }));
            setTimeout(() => {
                dispatch(Actions.comparaPrecio());
            }, 10);
            */
        }

    }


    const toMejorResultado = (indice, product) => {
        dispatch(Actions.toMejorResultado(indice, product))
    }




    return (

        <Stack direction="row" alignItems="center" spacing={1} mt={1}>

            <Tooltip title="Compara producto">
                <IconButton aria-label="Compara producto" size="small"
                    onClick={() => {
                        //console.log(props.data)
                        //  copy(item.mejorResultado.title)
                        enviar(props.data.title)
                    }}>
                    <IconCp fontSize="inherit" />
                </IconButton>
            </Tooltip>

            <Tooltip title="Copiar nombre">
                <IconButton aria-label="Copiar nombre" size="small"
                    onClick={() => {
                        copy(props.data.title)
                    }}>
                    <ContentCopyIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>


            <Tooltip title="Agregar a seleccionados">
                <IconButton aria-label="Agregar a seleccionados" size="small"
                    onClick={() => {
                        //  copy(item.mejorResultado.title)
                    }}>
                    <LibraryAddIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {/*
            <Tooltip title="Marcar">
                <IconButton aria-label="Marcar" size="small"
                    onClick={() => {
                        //  copy(item.mejorResultado.title)
                    }}>
                    <BookmarksIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>

            <Tooltip title="Compartir producto">
                <IconButton aria-label="Compartir producto" size="small"
                    onClick={() => {
                        //  copy(item.mejorResultado.title)
                    }}>
                    <ShareIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            */}

            {!props.upHidden &&
                <Tooltip title="Subir a destacado">
                    <IconButton aria-label="Subir a destacado" size="small"
                        onClick={() => {
                            //console.log(props.indice, props.data)
                            toMejorResultado(props.indice, props.data)
                        }}>
                        <VerticalAlignTopIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            }


        </Stack>
    )

}

const CustomSnackbar = ({ open, children, suspense, severity = 'info', cancel, duracion = 0 }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        /*
        onClose={() => { }}
        action={
            <button onClick={() => { }}>Close</button>
        }
            */
        >

            <Paper className='p-4 flex space-x-4 items-center' elevation={4}>


                <Box>

                    {suspense ?
                        <Box>

                            <Typography variant='body2'>Obteniendo información. Por favor aguarde. </Typography>
                            <Typography variant='body2'>Transcurrido: {duracion}s </Typography>

                        </Box> :
                        <Box>
                            {children}
                        </Box>
                    }

                </Box>
                <Box>
                    <Button onClick={() => { cancel() }} variant='outlined' size='small' className='pl-2 pr-2'>cancelar</Button>
                </Box>
            </Paper >
            {/*
            <Alert
                onClose={() => { }}
                severity={severity}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
            */}
        </Snackbar >
    );
};

const ImageBox = ({ imageUrl, fallbackUrl }) => {
    const [imageSrc, setImageSrc] = useState(imageUrl);

    useEffect(() => {
        // Verificar que el string comience con "https"
        const isValidUrl = imageUrl.startsWith('https');

        if (isValidUrl) {
            if (typeof window !== 'undefined') {
                const img = new window.Image();
                img.src = imageUrl;

                img.onload = () => {
                    setImageSrc(imageUrl);
                };

                img.onerror = () => {
                    setImageSrc(fallbackUrl);
                };
            }
        } else {
            setImageSrc(fallbackUrl);
        }
    }, [imageUrl, fallbackUrl]);


    if (imageSrc === '/no-img.png' || imageSrc.indexOf('oferta.png') !== -1 || imageSrc.indexOf('OfertaDigital.png') !== -1) {
        return (
            <Box
                className=""
                sx={{
                    width: 2,
                    height: 130,
                }} />
        )
    }


    return (
        <Box
            className="opacity-75 relative  bg-slate-400 bg-cover bg-center rounded-lg bg-no-repeat"
            sx={{
                width: 130,
                height: 130,
                backgroundImage: `url("${imageSrc}")`,
            }}
        >
            {/* Contenido adicional dentro del Box si es necesario */}
        </Box>
    );
};

const Resultados = (props) => {

    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down('md'));

    const swiperRef = useRef(null);
    const swiperRefs = useRef([]);
    const [swp, setSwp] = useState([])

    const dispatch = useDispatch();
    const state = useSelector(({ origenes }) => origenes.origenes);


    const quitarDeResultados = (index) => {
        dispatch(Actions.quitarOrigenResultado(index))
    }

    const quitarProductoDeResultados = (index, id) => {
        dispatch(Actions.quitarProductoResultado(index, id))
    }


    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (force = false) => {
        setSnackbarOpen(false);
        dispatch(Actions.updateCompara({ id: 'obteniendo', value: false }));
        dispatch(Actions.updateCompara({ id: 'buscando', value: false }));
        dispatch(Actions.updateCompara({ id: 'urlstream', value: '' }));
        if (force === true) {
            dispatch(Actions.updateCompara({ id: 'buscando', value: null }));
        }

    };


    function obtenerColorHex(index, total) {
        /*
        if (total <= 1) {
            throw new Error("El total debe ser mayor a 1.");
        }
        */
        // Determinar el color según el índice
        if (index === 0) {
            return '#00FF00'; // Verde
        } else if (index === total - 1) {
            return '#FF0000'; // Rojo
        } else {
            // Calcular el color degradado entre amarillo y rojo
            const factor = index / (total - 1);
            const rojo = Math.floor(255 * factor + 255 * (1 - factor));
            const verde = Math.floor(255 * (1 - factor));
            const colorHex = `#${rojo.toString(16).padStart(2, '0')}${verde.toString(16).padStart(2, '0')}00`; // Color en formato hexadecimal
            return colorHex;
        }
    }


    useEffect(() => {
        if (state && state.urlstream) {
            const url = apiUrl + `/sse/${state.urlstream}`;
            let eventSource;

            const connect = () => {
                eventSource = new EventSource(url);

                const handleDoneEvent = (event) => {
                    try {
                        const eventData = JSON.parse(event.data);
                        if (eventData.resultados && eventData.resultados.length > 0) {
                            dispatch(Actions.resultadosFinal(eventData.resultados, true));
                            dispatch(Actions.updateCompara({ id: 'urlstream', value: '' }));
                            dispatch(Actions.updateCompara({ id: 'obteniendo', value: false }));
                            dispatch(Actions.updateCompara({ id: 'duracion', value: 0 }));
                            //console.log('--> Evento Done recibido:', eventData);
                        }
                        eventSource.close();
                    } catch (error) {
                        console.error('Error al procesar evento Done:', error);
                    }
                };

                const handleOutputEvent = (event) => {
                    try {
                        const eventData = JSON.parse(event.data);
                        if (eventData.resultados && eventData.resultados.length > 0) {
                            dispatch(Actions.resultadosFinal(eventData.resultados, true));
                            dispatch(Actions.updateBuscando(false));
                            dispatch(Actions.updateCompara({ id: 'obteniendo', value: true }));
                            dispatch(Actions.updateCompara({ id: 'duracion', value: eventData.q }));
                        }
                        //console.log('--> Evento output recibido:', eventData);
                    } catch (error) {
                        console.error('Error al procesar evento Data:', error);
                    }
                };

                const handleError = (event) => {
                    dispatch(Actions.updateCompara({ id: 'duracion', value: '-' }));
                    console.error('Error de conexión:', event);
                    eventSource.close();

                    // Intentar reconectar después de un tiempo
                    setTimeout(() => {
                        console.log('Reintentando conexión...');
                        connect();
                    }, 5000); // Intenta reconectar después de 5 segundos
                };

                // Asignar eventos
                eventSource.addEventListener('data', handleOutputEvent);
                eventSource.addEventListener('done', handleDoneEvent);
                eventSource.onerror = handleError;
            };

            // Iniciar conexión
            connect();

            // Limpiar efecto
            return () => {
                if (eventSource) {
                    // eventSource.removeEventListener('data', handleOutputEvent);
                    // eventSource.removeEventListener('done', handleDoneEvent);
                    eventSource.close();
                }
            };
        }
    }, [state.urlstream, dispatch]);


    useEffect(() => {
        // Solo actualizar los Swipers si state.obteniendo es false
        if (state.obteniendo === false) {
            //console.log(state.resultados);
            //console.log(swiperRefs.current);

            // Verificar si swiperRefs.current es un array válido antes de usar forEach
            if (Array.isArray(swiperRefs.current)) {
                swiperRefs.current.forEach((swiper) => {
                    if (swiper) {
                        swiper.update(); // Actualizar cada Swiper
                    }
                });
            }
        }
    }, [state.resultados, state.obteniendo]); // Ejecutar el efecto cuando state.resultados o state.obteniendo cambie



    if (state.error === true) {
        return (
            <React.Fragment>
                <Box>{state.errorMensaje}</Box>
            </React.Fragment>
        )
    }

    if (state.buscando === null) {
        return (
            <React.Fragment>
                <TerminosBuscados {...props} />
            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
            {state.buscando === true || (!state.resultados || state.resultados && state.resultados.length === 0) ?
                (
                    <Box className="p-8">
                        <Paper className='p-4'>
                            Buscando....
                            <Button onClick={() => handleCloseSnackbar(true)}>cancelar</Button>
                        </Paper>
                        <Paper className='p-4 mt-4'>
                            <Box
                                style={{ width: '100%', height: 126 }}
                                className='mb-2 border border-slate-800 rounded-lg flex justify-center items-center'>
                                {/*
                                <AdSense
                                    adClient="ca-pub-7542824617421637"  // Reemplaza con tu ID de cliente
                                    adSlot="8898268774"      // Reemplaza con tu ID de slot de anuncio
                                />*/}
                            </Box>
                        </Paper>
                    </Box>
                ) : (
                    <Swiper
                        //direction={state.layoutDirection === 'vertical' ? "horizontal" : 'vertical'}
                        direction={"horizontal"}
                        slidesPerView={'auto'}
                        className="mySwiper bg-red-400- h-full"
                        style={{
                            height: '100%',
                            "--swiper-navigation-color": "#9c27b0",
                            "--swiper-pagination-color": "#9c27b0",
                            "--swiper-pagination-bullet-size": "10px",
                            "--swiper-pagination-bullet": '#212121',
                        }}
                        navigation={!match}
                        modules={[
                            Navigation
                        ]}
                        mousewheel={true}
                        pagination={{
                            type: 'fraction',
                        }}
                        ref={swiperRef}
                    >


                        {state && state.resultados && state.resultados.map((item, index) => (
                            <SwiperSlide key={index} style={{ maxWidth: 360 }}>

                                <Paper className="p-2 m-1 flex flex-col h-full- bg-red-400-">

                                    <Box className="flex mb-1 pl-1 pr-1">
                                        <Box className="flex-1 capitalize pb-2 mb-2 mr-2" style={{ borderBottom: '1px solid #9c27b0' }}>
                                            <Typography variant='body2' >{item.nombre} </Typography>
                                        </Box>
                                        <Box className="">

                                            <Tooltip title="Quitar de resultados">
                                                <IconButton aria-label="Quitar de resultados" size="small"
                                                    onClick={() => {
                                                        quitarDeResultados(index)
                                                    }}>
                                                    <RemoveIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box
                                        className="rounded"
                                    //style={{ height: 150 }}
                                    //style={{ border: '1px solid ' + obtenerColorHex(index, state.resultados.length) }}
                                    >
                                        {
                                            item.mejorResultado && item.mejorResultado.title &&
                                            <Paper
                                                style={{ height: 160 }}
                                                className="border- p-2 flex space-x-16- h-full- mb-2"
                                                elevation={0}>

                                                <ImageBox
                                                    imageUrl={item.mejorResultado.image}
                                                    fallbackUrl="/no-img.png"
                                                />


                                                <Box className="flex-1 p-2">
                                                    <Box className="flex">
                                                        <Typography variant="body2" className='flex-1'>{item.mejorResultado.title}</Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            maxWidth: 150
                                                        }}
                                                    >
                                                        <Typography variant="h6">
                                                            <Valor
                                                                className={'opacity-75'}
                                                                //style={{ color: obtenerColorHex(index, state.resultados.length) }}
                                                                v={item.mejorResultado.price} />
                                                        </Typography>
                                                    </Box>

                                                    <Botones data={item.mejorResultado} upHidden />
                                                </Box>



                                            </Paper>
                                        }
                                    </Box>

                                    <Divider className='mb-4' style={{ borderColor: '#7b1fa2' }} />

                                    <Box className="flex-1 border- h-full relative" >

                                        <Swiper
                                            onSwiper={(swiper) => (swiperRefs.current[index] = swiper)} // Asignar ref dinámicamente
                                            //direction={dir}
                                            direction={state.layoutDirection !== 'vertical' ? "horizontal" : 'vertical'}
                                            slidesPerView={'auto'}
                                            freeMode={true}
                                            scrollbar={true}
                                            mousewheel={true}
                                            modules={[
                                                FreeMode,
                                                Scrollbar,
                                                Mousewheel,
                                                //Virtual
                                            ]}
                                            //virtual
                                            //className="flex-1 border h-full"
                                            //nested={true}
                                            style={{
                                                //height: 'calc(100vh - 380px)' //con ActionsBar
                                                height: 'calc(100vh - 315px)'
                                            }}
                                            pagination={{
                                                type: 'fraction',
                                            }}
                                        /*
                                        onInit={(s) => {
                                            console.log(s)
                                            setSwp([...swp, s])
                                        }}
                                        */
                                        >



                                            <SwiperSlide
                                                style={{ height: 'auto', }}
                                            //virtualIndex={index}
                                            >

                                                {item.resultados && item.resultados.map((items, index1) => (
                                                    <Box key={index1} className="pl2 pr-4">
                                                        <Paper className="flex border- mb-2 p-2 h-full" elevation={0}>

                                                            <ImageBox
                                                                imageUrl={items.image}
                                                                fallbackUrl="/no-img.png"
                                                            />

                                                            <Box className="flex-1 pl-4">


                                                                <Box className="flex">
                                                                    <Typography variant="body2" className='flex-1'>{items.title}</Typography>
                                                                    <Box>
                                                                        <Tooltip title="Quitar de resultados">
                                                                            <IconButton aria-label="Quitar de resultados" size="small" className='ml-2'
                                                                                onClick={() => {
                                                                                    quitarProductoDeResultados(index, items.id)
                                                                                }}>
                                                                                <RemoveIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>

                                                                <Box className="mt-2"
                                                                    sx={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: 150
                                                                    }}
                                                                >
                                                                    <Typography variant='h6'>

                                                                        <Valor v={items.price} />

                                                                    </Typography>

                                                                </Box>

                                                                <Botones data={items} indice={index} />


                                                            </Box>
                                                        </Paper>
                                                        {index1 === 0 && state.publicidad === true &&
                                                            <Box
                                                                style={{ width: '100%', height: 126 }}
                                                                className='mb-2 border border-slate-800 rounded-lg flex- justify-center- items-center-'>
                                                               <Typography variant='caption' className='ml-2 mt-2'>PUBLICIDAD</Typography> 
                                                                <AdSense
                                                                    adClient="ca-pub-7542824617421637"  // Reemplaza con tu ID de cliente
                                                                    adSlot="8898268774"      // Reemplaza con tu ID de slot de anuncio
                                                                    fixedHeight="130px"
                                                                />
                                                                 {/*
                                                                <Adsterra />*/}
                                                                
                                                            </Box>
                                                        }
                                                        {index1 === 100 && state.publicidad === true &&
                                                            <Box
                                                                style={{ width: '100%', height: 126 }}
                                                                className='mb-2 border border-slate-800 rounded-lg flex justify-center items-center'>
                                                                {/*
                                                                <Adsterra />
                                                                */}
                                                                ADS
                                                            </Box>
                                                        }
                                                        {index1 === 40 && state.publicidad === true &&
                                                            <Box
                                                                style={{ width: '100%', height: 126 }}
                                                                className='mb-2 border border-slate-800 rounded-lg flex justify-center items-center'>
                                                                {/*
                                                                <AdSense
                                                                    adClient="ca-pub-7542824617421637"  // Reemplaza con tu ID de cliente
                                                                    adSlot="8898268774"      // Reemplaza con tu ID de slot de anuncio
                                                                    fixedHeight="130px"
                                                                />
                                                                <Adsterra />*/}
                                                                ADS
                                                            </Box>
                                                        }
                                                    </Box>
                                                ))}


                                            </SwiperSlide>


                                        </Swiper>


                                    </Box>

                                </Paper>

                            </SwiperSlide >
                        ))}



                    </Swiper>
                )}


            {(state.obteniendo === true && state.buscando === false) &&
                <Box className="fixed  bottom-0 left-0 top-0 right-0 h-full w-full bg-black opacity-75 flex items-center justify-center" style={{ zIndex: 1000 }}  >
                    <CircularProgress color="success" />
                </Box>
            }

            <CustomSnackbar
                //open={true}
                open={(state.obteniendo === true && state.buscando === false) || false}
                //message={"Obteniendo... (" + (state.origenes || 0) + ") con " + (state.totales || 0) + " resultados totales."}
                onClose={handleCloseSnackbar}
                duracion={state.duracion}
                suspense={state.origenes > 0 ? false : true}
                cancel={() => {
                    //console.log('cancela')
                    handleCloseSnackbar()
                }}
            >
                {
                    //({(state.origenes || 0)}) con 
                }
                <Typography variant='body2'>Obteniendo... ({state.totales || 0}) resultados</Typography>
                <Typography variant='body2'>Transcurrido: {state.duracion}s</Typography>
            </CustomSnackbar>
        </React.Fragment>

    )


}

export default Resultados